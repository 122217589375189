import {
  Collapse,
  Empty,
  Radio,
  RadioChangeEvent,
  Row,
  Skeleton,
  Tabs,
} from "antd";
import { FC, useCallback, useState } from "react";
import { axiosInstanceV2 } from "src/helpers";
import BonusGraph from "./BonusCashGraph";

import CashGraph from "./CashGraph";
import GemsGraph from "./GemsGraph";
import {
  getGraphSettings,
  saveGraphSettings,
} from "src/shared/config/constants";

const { Panel } = Collapse;

interface Props {
  userId: string;
}

const GraphTypeOptions = ["Line", "Bar"];

const BalanceGraph: FC<Props> = ({ userId }) => {
  const [historicalBalance, setHistoricalBalance] =
    useState<HistoryResponse[]>();

  const [graphType, setGraphType] = useState<"Bar" | "Line">(
    () => getGraphSettings() || "Line"
  );
  const [isLoading, setIsLoading] = useState(false);

  const getBalanceHistoricalData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstanceV2.post<HistoryResponse[]>(
        "/admin/user_balance_history/",
        { userId }
      );

      if (response.status === 200 && response.data) {
        setHistoricalBalance(response.data);
      }
    } catch (error) {
      console.error("failed to load balance historical graph");
    } finally {
      setIsLoading(false);
    }
  }, [userId]);

  const onToggle = (e: string[] | string) => {
    if (e.length !== 0 && !historicalBalance) {
      getBalanceHistoricalData();
    }
  };

  const onToggleGraph = (e: RadioChangeEvent) => {
    setGraphType(e.target.value);
    saveGraphSettings(e.target.value);
  };
  return (
    <>
      <Collapse onChange={onToggle} ghost>
        <Panel header="User Historical Balance/Gems Graph" key="1">
          <Row justify="end" className="mb-1">
            <Radio.Group
              options={GraphTypeOptions}
              onChange={onToggleGraph}
              value={graphType}
              optionType="button"
              buttonStyle="solid"
            />
          </Row>
          {isLoading ? (
            <Skeleton paragraph />
          ) : !historicalBalance ? (
            <Empty description="Historical balance information is missing" />
          ) : (
            <Tabs
              tabPosition="left"
              items={[
                {
                  label: "Bonus Cash",
                  id: "bonusCash",
                  key: "bonusCash",
                  children: (
                    <BonusGraph
                      graphType={graphType}
                      historicalBalance={historicalBalance}
                    />
                  ),
                },
                {
                  label: "Cash",
                  id: "cash",
                  key: "cash",
                  children: (
                    <CashGraph
                      graphType={graphType}
                      historicalBalance={historicalBalance}
                    />
                  ),
                },
                {
                  label: "Gems",
                  id: "gems",
                  key: "gems",
                  children: (
                    <GemsGraph
                      graphType={graphType}
                      historicalBalance={historicalBalance}
                    />
                  ),
                },
              ]}
            />
          )}
        </Panel>
      </Collapse>
    </>
  );
};

export default BalanceGraph;
