import {
  Avatar,
  Button,
  Col,
  Divider,
  Row,
  Skeleton,
  Tag,
  Typography,
} from "antd";
import { UserOutlined, LoadingOutlined } from "@ant-design/icons";

import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  Unsubscribe,
  where,
} from "firebase/firestore";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  colGroupNames,
  colNames,
  getCollection,
  getCollectionGroup,
} from "src/collections";
import { userStateConverter } from "src/converters";

import {
  axiosInstanceV2,
  db,
  generateFirestorePath,
  getPhoto,
} from "src/helpers";
import TriumphPage from "src/shared/layout/TriumphPage";
import UserActionButtons from "./components/actions";
import TransactionDetails from "./components/transactionDetails";
import UserGameStates from "./components/userGameState";
import UserInfo from "./components/userInformation";
import { UserTournament } from "./components/userTournaments";
import { useMediaQuery } from "react-responsive";
import AbuseEvents from "./components/abuseEvents";

const { Title } = Typography;

const UserDetails: FC<{ expandedUser?: string }> = ({ expandedUser }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const { id: paramUserId } = useParams();
  const id = expandedUser ?? paramUserId;
  const [statsData, setStatsData] = useState<UserStatisticsAmountsResponse>();
  const [appUser, setAppUser] = useState<AppUser | null>(null);
  const [userPublic, setUserPublic] = useState<AppUserPublic | null>(null);
  const [userState, setUserState] = useState<UserState>();
  const [tournamentsList, setTournamentsList] = useState<Array<UserTournament>>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll({ top: 0 });
  }, [paramUserId]);

  const [referralData, setReferralData] = useState<Referral>();
  const getTournementsByUserId = useCallback(async () => {
    const blitzTournamentsCol = getCollectionGroup(
      colGroupNames.blitzTournamentsV2
    );

    const blitzTournamentsQuery = query(
      blitzTournamentsCol,
      where("uid", "==", id)
    );

    const asyncGroupTournamentsCol = getCollectionGroup(
      colGroupNames.groupTournaments
    );

    const asyncGroupTournamentsQuery = query(
      asyncGroupTournamentsCol,
      where("participants", "array-contains", id)
    );

    const snapShotAsyncGroup = await getDocs(asyncGroupTournamentsQuery);

    const snapShotBlitz = await getDocs(blitzTournamentsQuery);

    setIsLoading(true);
    let blitzData: UserTournament[] = [];
    snapShotBlitz.forEach((doc) => {
      if (doc.exists()) {
        const data = doc.data();
        const trnmnt = data.tournamentUid;
        const game = data.gameId;
        const url = `/games/${game}/tournaments/blitz/${trnmnt}`;

        blitzData.push({ url, type: "blitz", tournamentId: trnmnt });
      }
    });

    let asyncGroupData: UserTournament[] = [];
    snapShotAsyncGroup.forEach((doc) => {
      if (doc.exists()) {
        const data = doc.data();

        const trnmnt = data.uid;
        const game = data.gameId;

        const url = `/games/${game}/tournaments/asyncGroup/${trnmnt}`;
        asyncGroupData.push({ url, type: "async-group", tournamentId: trnmnt });
      }
    });
    setIsLoading(false);

    setTournamentsList([...asyncGroupData, ...blitzData]);
  }, [id]);

  const getReferral = useCallback(async (id: string) => {
    const referralsRef = getCollection(colNames.referrals);

    const referralQuery = query(referralsRef, where("appUserUid", "==", id));
    const snap = await getDocs(referralQuery);

    setReferralData(snap.docs[0]?.data());
  }, []);

  useEffect(() => {
    let unsubUserStateCol: Unsubscribe;
    let unsubAppUser: Unsubscribe;
    let unsubPublicUser: Unsubscribe;
    if (id) {
      setIsLoading(true);

      getReferral(id);
      getTournementsByUserId();

      const userColRef = getCollection(colNames.appUsers);
      const publicUserColRef = getCollection(colNames.appUsersPublic);

      const appUserQuery = doc(userColRef, id);
      const publicUserQuery = doc(publicUserColRef, id);

      unsubAppUser = onSnapshot(appUserQuery, (colSnap) => {
        setAppUser(colSnap.data() || null);
      });

      unsubPublicUser = onSnapshot(publicUserQuery, (colSnap) => {
        setUserPublic(colSnap.data() || null);
        setIsLoading(false);
      });

      const userStateRef = collection(
        db,
        generateFirestorePath("appUserAbuse")
      ).withConverter(userStateConverter);

      const userStateDocRef = doc(userStateRef, id);

      unsubUserStateCol = onSnapshot(userStateDocRef, (colSnap) => {
        setUserState(colSnap.data());
        setIsLoading(false);
      });
    }
    return () => {
      unsubUserStateCol?.();
      unsubPublicUser?.();
      unsubAppUser?.();
    };
  }, [getTournementsByUserId, getReferral, id]);

  useEffect(() => {
    const getUserStatistics = async () => {
      try {
        const response =
          await axiosInstanceV2.post<UserStatisticsAmountsResponse>(
            "internal/users/user_fraud_stats",
            {
              appUserUid: id,
            }
          );

        if (response.status === 200) {
          setStatsData(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (id) {
      getUserStatistics();
    }
  }, [id]);

  if (!userPublic || !appUser) return <Skeleton avatar paragraph title />;

  const user: AppUser & AppUserPublic = {
    ...appUser,
    ...userPublic,
  };

  return (
    <TriumphPage>
      <Row justify="end">
        <UserActionButtons
          hideGoBack={!!expandedUser}
          userState={userState}
          user={user}
        />
      </Row>

      <Row align="middle">
        <Col span={!isMobile ? 3 : 9}>
          <Avatar src={getPhoto(user)} size={64} icon={<UserOutlined />} />
        </Col>
        <Col>
          <Title level={3}>
            {user.username}{" "}
            {user.vipStatus && user.vipStatus === "vip-1" && (
              <Tag color="green">VIP</Tag>
            )}
            {user.isAnon && <Tag color="blue">Anonymous</Tag>}
            {user.isFirstTime === false && (
              <Tag color="yellow">Multiple Accounts</Tag>
            )}
            {isLoading && <LoadingOutlined size={64} />}
          </Title>
        </Col>
      </Row>
      <Divider />

      <UserInfo userStats={statsData} refferal={referralData} user={user} />
      <Divider />
      <UserGameStates user={user} />
      <Divider />
      <AbuseEvents user={user} />
      <TransactionDetails
        expandedView={!!expandedUser}
        userTournament={tournamentsList}
        userState={userState}
        user={user}
      />
      {!expandedUser && (
        <Row justify="end">
          <Button type="ghost" className="mr-1" onClick={() => navigate(-1)}>
            Go Back
          </Button>
        </Row>
      )}
    </TriumphPage>
  );
};
export default UserDetails;
