import { FormInstance } from "antd/es/form/Form";
import { RuleObject } from "antd/lib/form";
import dayjs from "dayjs";
import { capitalize } from "lodash";

export const dateFormat = "M/D/YY h:mm A";
export const dateFormatter = (date?: any, format?: string): string => {
  if (["undefined", "null"].includes(typeof date)) return "-";
  const isValid = dayjs(date).isValid();
  if (!isValid) return "-";
  return dayjs(date).format(format || dateFormat);
};

export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100, 150, 200];

export function validateNumericValue(rule: RuleObject, value: number | string) {
  if (Number(value) === 0) {
    return Promise.reject("value should not be zero");
  }
  if (value === undefined || value === "" || value === null) {
    return Promise.reject("value is required");
  }
  return Promise.resolve();
}

export const validatePositiveNumericValue =
  (name: string, min?: number) =>
  (rule: RuleObject, value: number | string) => {
    const minimum = min || 0;
    if (Number(value) < minimum) {
      return Promise.reject(`${name} should not be less than ${minimum}`);
    }
    if (value === undefined || value === "" || value === null) {
      return Promise.reject(`${name} is required`);
    }
    return Promise.resolve();
  };

export function formSubmit<T>(form: FormInstance<T>) {
  return (onSuccess: (v: T) => void, onError?: (e: unknown) => void) => {
    form.submit();

    form
      .validateFields()
      .then((value) => {
        onSuccess(value);
      })
      .catch((error) => {
        if (onError) {
          onError(error);
        }
      });
  };
}

export const validateStringValue = (rule: RuleObject, value: string) => {
  if (typeof value !== "string" || value === "") {
    return Promise.reject();
  }
  return Promise.resolve();
};

export const EMAIL_REGEX = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const versionRegex = /^([0-9]+)\.([0-9]+)\.([0-9]+)$/;

export function onRefresh() {
  localStorage.removeItem("async-tournament-table-filters");
  localStorage.removeItem("blitz-tournament-table-filters");
}

export function clearLocalStorage() {
  localStorage.clear();
}

const GRAPH_SETTING_KEY = "graph-type";
export function saveGraphSettings(value: string) {
  localStorage.setItem(GRAPH_SETTING_KEY, value);
  return localStorage.getItem(GRAPH_SETTING_KEY);
}

export function getGraphSettings<T>() {
  return localStorage.getItem(GRAPH_SETTING_KEY) as T;
}

export function separateByCapitalLetters(str: string) {
  const words = [];
  let currentWord = "";
  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    if (char >= "A" && char <= "Z") {
      if (currentWord) {
        // Check if the last character of the current word is a capital letter
        if (
          currentWord[currentWord.length - 1] >= "A" &&
          currentWord[currentWord.length - 1] <= "Z"
        ) {
          currentWord += char;
        } else {
          words.push(currentWord);
          currentWord = char;
        }
      } else {
        currentWord = char;
      }
    } else {
      currentWord += char;
    }
  }
  if (currentWord) {
    words.push(currentWord);
  }

  words[0] = capitalize(words[0]);

  return words.join(" ");
}
